import areaApi from 'api/Area'
import { redirectToSelectArea, getParamFromURL, removeListParamOnURL } from 'utils/booking/common'
import { currentAreaActionsCreator } from 'store/toolkit/currentArea/currentArea.reducer'
import { extraInfosActionsCreator } from 'store/toolkit/extraInfos/extraInfos.reducer'
import PATHS from 'routers/paths'
import * as areaActionCreators from 'store/actions/common/areaActionCreators'
import * as utilsCommon from 'utils/booking/common'
import CommonUtils from 'utils/common'


export const getCurrentAreaCustomer = (countryCode) => async (dispatch) => {
  const currentAreaIdCustomer = await areaApi.getCurrentArea({
    ...(countryCode && { country_code: countryCode }),
    only: ['id']
  })
  if (currentAreaIdCustomer) {
    removeListParamOnURL(['area_id'])
    dispatch(getAreaInfo(currentAreaIdCustomer))
  } else {
    if(window.location.pathname !== PATHS.SELECT_AREA && !CommonUtils.isExportOrDownloadAnalyticsLater()) {
      redirectToSelectArea()
    }
  }
}

let countCallApi = 0
export const getAreaInfo = areaId => async (dispatch) => {
  const dataServiceArea = await areaApi.getServiceArea(areaId)
  if(dataServiceArea.status === 500 && countCallApi < 5) {
    countCallApi++
    const countryCode = getParamFromURL('country_code')
    return dispatch(getCurrentAreaCustomer(countryCode))
  }
  const { object: objectArea, dropdown_areas: dropdownAreas, languages } = dataServiceArea

  dispatch(currentAreaActionsCreator.setCurrentArea({
    ...objectArea,
    supported_zone: objectArea?.settings?.supported_zone || []
  }))

  // set area_id after run getCustomerSettings
  if (objectArea?.id) {
    delete objectArea.id
  }
  dispatch(extraInfosActionsCreator.updateExtraInfos({
    ...objectArea,
    country_language: objectArea?.language || 'en',
    listLanguage: languages,
    dropdownAreas
  }))
}

export const getAreaSetting = (authenticationToken, currentCustomer, finalAreaId) => dispatch => {
  if (!finalAreaId) {
    const countryCode = utilsCommon.getParamFromURL('country_code')
    dispatch(areaActionCreators.getCurrentAreaCustomer(countryCode))
  }
  if (authenticationToken && !currentCustomer.id) return
  if (finalAreaId) {
    dispatch(areaActionCreators.getAreaInfo(finalAreaId))
    // sync area_id for session FTL
    areaApi.setNewCurrentAreaSession(finalAreaId)
  }
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState: any = {}

const bookingTrackingSlice = createSlice({
  name: 'bookingTracking',
  initialState,
  reducers: {
    bookingTrackingUpdate: (state, action: PayloadAction<any>) => ({ ...state, ...action.payload }),
    resetBookingTracking: () => ({}),
  },
  extraReducers: () => {},
})

export const bookingTrackingActionsCreator = bookingTrackingSlice.actions

export default bookingTrackingSlice

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { configureStore, createListenerMiddleware, TypedStartListening, combineReducers } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
// Common
import batchSlice from './toolkit/batch/batch.reducer'
import bookingSlice from './toolkit/bookings/booking.reducer'
import analyticsEmailSlice from './toolkit/analyticsEmail/analyticsEmail.reducer'
import currentAreaSlice from './toolkit/currentArea/currentArea.reducer'
import isBookingSlice from './toolkit/isBooking/isBooking.reducer'
import batchTemplatesSlice from './toolkit/batchTemplates/batchTemplates.reducer'
import calendarBookingSlice from './toolkit/calendarBooking/calendarBooking.reducer'
import checkSubAccountSlice from './toolkit/checkSubAccount/checkSubAccount.reducer'
import contactsSlice from './toolkit/contacts/contacts.reducer'
import currentPopupIDSlice from './toolkit/currentPopupID/currentPopupID.reducer'
import switchAccountSlice from './toolkit/switchAccount/switchAccount.reducer'
import currentStepSlice from './toolkit/currentStep/currentStep.reducer'
import extraInfosSlice from './toolkit/extraInfos/extraInfos.reducer'
import infoAssignDriverPopupSlice from './toolkit/infoAssignDriverPopup/infoAssignDriverPopup.reducer'
import outOfServiceStatusSlice from 'store/toolkit/outOfServiceStatus/outOfServiceStatus.reducer'
import currentCustomerSlice from './toolkit/currentCustomer/currentCustomer.reducer'

import personalContactsSlice from './toolkit/personalContacts/personalContacts.reducer'
import popupCodSlice from './toolkit/popupCod/popupCod.reducer'
import popupContentManagementSlice from './toolkit/popupContentManagement/popupContentManagement.reducer'
import recentLocationsSlice from './toolkit/common/recentLocations/recentLocations.reducer'
import requireSignaturesSlice from './toolkit/requireSignatures/requireSignatures.reducer'
import savedBookingsSlice from './toolkit/savedBookings/savedBookings.reducer'
import showRouteSlice from './toolkit/showRoute/showRoute.reducer'
import subAccountTagListSlice from './toolkit/subAccountTagList/subAccountTagList.reducer'
import subCurrentPopupIDSlice from './toolkit/subCurrentPopupID/subCurrentPopupID.reducer'
import validateStepSlice from './toolkit/validateStep/validateStep.reducer'
import vehicleSlice from './toolkit/vehicle/vehicle.reducer'
import polylineSlice from './toolkit/polyline/polyline.reducer'
import prevRouteSlice from './toolkit/prevRoute/prevRoute.reducer'
import prevLocationsSlice from './toolkit/prevLocations/prevLocations.reducer'
import prevOptimizeRouteSlice from './toolkit/prevOptimizeRoute/prevOptimizeRoute.reducer'
import waypointOrderSlice from './toolkit/waypointOrder/waypointOrder.reducer'
import selectedBookingSlice from './toolkit/selectedBooking/selectedBooking.reducer'
import selectedLocationSlice from './toolkit/selectedLocation/selectedLocation.reducer'
import locationIdChangeSlice from './toolkit/locationIdChange/locationIdChange.reducer'
import mapServiceSlice from './toolkit/common/mapService.reducer'

// ===== DashBoardAnalyticsReducer
import dashBoardAnalyticsReducer from './toolkit/dashboardAnalytics'

// EzSpreadSheetReducer
import newContactSlice from './toolkit/newContact/newContact.reducer'
import batchTemplateSlice from './toolkit/batchTemplate/batchTemplate.reducer'
import pickupContactsSlice from './toolkit/pickupContacts/pickupContacts.reducer'
import excelMatrixSlice from './toolkit/excelMatrix/excelMatrix.reducer'
import newLocationSlice from './toolkit/newLocation/newLocation.reducer'
import stepUploadTabsDataSlice from './toolkit/stepUploadTabsData/stepUploadTabsData.reducer'

// ===== MultipleBookingsReducer
import batchMultipleSlice from './toolkit/batch/batchesMultiple.reducer'
import bookingsSlice from './toolkit/bookings/bookings.reducer'

// ===== PreferenceDriverReducer
import driversSlice from './toolkit/drivers/drivers.reducer'

// ===== SmartPlannerReducer
import pickupTimesSlice from './toolkit/pickupTimes/pickupTimes.reducer'
import mapsSlice from './toolkit/map/map.reducer'

// ===== BookingCardsReducer
import myBookingSlice from './toolkit/myBooking.reducer'

import extraServicesSlice from './toolkit/extraServices/extraServices.reducer'

import newBookingReducer from './toolkit/newBooking'
import batchManagementReducer from './toolkit/batch'
import paginationReducer from './toolkit/pagination'
import accountManageSlice from './toolkit/accountManage/accountManage.reducer'
import pickupTimeSmartPlannerSlice from './toolkit/smartPlanner/pickupTimeSmartPlannerSlice.reducer'
import mapSmartPlannerSlice from './toolkit/smartPlanner/mapSmartPlannerSlice.reducer'
import serviceTypesSmartPlannerSlice from './toolkit/smartPlanner/serviceTypesSmartPlannerSlice.reducer'
import currentTabEzsheetSlice from './toolkit/currentTabEzsheet/currentTabEzsheet.reducer'
import optimizeRoutesSmartPlannerSlice from './toolkit/smartPlanner/optimizeRoutesSmartPlannerSlice.reducer'
import gridColumnsSlice from './toolkit/gridColumns/gridColumns.reducer'
import serviceTypesCommonSlice from './toolkit/serviceTypes/serviceTypesCommon.reducer'
import errorAddressSlice from './toolkit/errorAddress/errorAddress.reducer'
import focusInputSlice from './toolkit/focusInput/focusInput.reducer'

// ===== BookingDetail
import bookingDetailSlice from './toolkit/bookingDetail/bookingDetail.reducer'
import devinaSlice from './toolkit/bookingDetail/devina.reducer'
import customerDriverSlice from './toolkit/bookingDetail/customerDriver.reducer'

// ===== BookingTracking
import bookingTrackingSlice from './toolkit/bookingTracking/bookingTracking.reducer'
import trackingMarkerSlice from 'store/toolkit/trackingMarkers/trackingMarker.reducer'

const listenerMiddlewareInstance = createListenerMiddleware({
  onError: () => console.error,
})

const rootReducer = combineReducers({
  // ===== Common
  batch: batchSlice.reducer,
  booking: bookingSlice.reducer,
  analyticsEmail: analyticsEmailSlice.reducer,
  currentCustomer: currentCustomerSlice.reducer,
  currentArea: currentAreaSlice.reducer,
  batchTemplates: batchTemplatesSlice.reducer,
  calendarBooking: calendarBookingSlice.reducer,
  checkSubAccount: checkSubAccountSlice.reducer,
  contacts: contactsSlice.reducer,
  currentPopupID: currentPopupIDSlice.reducer,
  switchAccount: switchAccountSlice.reducer,
  currentStep: currentStepSlice.reducer,
  extraInfos: extraInfosSlice.reducer,

  // newbooking, ez-sheet, slp
  outOfServiceStatus: outOfServiceStatusSlice.reducer,
  // newboking, ez-sheet, slp
  extraServices: extraServicesSlice.reducer,
  isBooking: isBookingSlice.reducer,
  infoAssignDriverPopup: infoAssignDriverPopupSlice.reducer,
  gridColumns: gridColumnsSlice.reducer,

  // pagination
  ...paginationReducer,

  personalContacts: personalContactsSlice.reducer,
  // newbooking, multiple
  popupCOD: popupCodSlice.reducer,
  popupContentManagement: popupContentManagementSlice.reducer,
  recentLocations: recentLocationsSlice.reducer,
  requireSignatures: requireSignaturesSlice.reducer,
  // ez-sheet, multiple
  savedBookings: savedBookingsSlice.reducer,
  serviceTypes: serviceTypesCommonSlice.reducer,

  // newbooking, ez-sheet, multiple
  showRoute: showRouteSlice.reducer,
  polyline: polylineSlice.reducer,
  prevRoute: prevRouteSlice.reducer,
  prevLocations: prevLocationsSlice.reducer,
  prevOptimizeRoute: prevOptimizeRouteSlice.reducer,
  waypointOrder: waypointOrderSlice.reducer,

  subAccountTagList: subAccountTagListSlice.reducer,

  subCurrentPopupID: subCurrentPopupIDSlice.reducer,
  validateStep: validateStepSlice.reducer,
  vehicles: vehicleSlice.reducer,
  selectedBooking: selectedBookingSlice.reducer,
  selectedLocation: selectedLocationSlice.reducer,
  locationIdChange: locationIdChangeSlice.reducer,

  // ===== BatchManagementReducer
  ...batchManagementReducer,

  // ===== NewBookingReducer
  ...newBookingReducer,
  mapService: mapServiceSlice.reducer,
  accountModal: accountManageSlice.reducer,
  // ===== DashBoardAnalyticsReducer
  ...dashBoardAnalyticsReducer,

  // ===== EzSpreadSheetReducer
  newContact: newContactSlice.reducer,
  batchTemplate: batchTemplateSlice.reducer,
  pickupContacts: pickupContactsSlice.reducer,
  errorAddress: errorAddressSlice.reducer,
  focusInput: focusInputSlice.reducer,
  // we must to handle currentTab for batch
  // const currentTab = (state = STEP_UPLOAD_TAB_OUR_TEMPLATE, action) => {
  //   switch (action.type) {
  //     case CURRENT_TAB_UPDATE:
  //       return action.tab
  //     default:
  //       return state
  //   }
  // }
  // currentTabBatch: currentTabSlice.reducer,
  excelMatrix: excelMatrixSlice.reducer,
  newLocation: newLocationSlice.reducer,
  stepUploadTabsData: stepUploadTabsDataSlice.reducer,

  // ===== MultipleBookingsReducer
  batchMultiple: batchMultipleSlice.reducer,
  bookings: bookingsSlice.reducer,

  // ===== PreferenceDriverReducer
  drivers: driversSlice.reducer,

  // ===== SmartPlannerReducer
  // We can handle next sprint, hu hu
  smartPlanner: combineReducers({
    pickup: pickupTimeSmartPlannerSlice.reducer,
    template: batchTemplateSlice.reducer,
    optimizeRoutes: optimizeRoutesSmartPlannerSlice.reducer,
    map: mapSmartPlannerSlice.reducer,
    serviceTypesForBundle: serviceTypesSmartPlannerSlice.reducer,
  }),

  // currentTabCommon
  map: mapsSlice.reducer,
  pickup: pickupTimesSlice.reducer,
  // serviceTypesForBundle

  // ===== BookingCardsReducer
  currentTabEzsheet: currentTabEzsheetSlice.reducer,

  myBooking: myBookingSlice.reducer,

  // ===== bookingDetail
  bookingDetail: bookingDetailSlice.reducer,
  devina: devinaSlice.reducer,
  customerDriver: customerDriverSlice.reducer,

  // ===== TrackingBooking
  bookingTracking: bookingTrackingSlice.reducer,
  trackingMarkers: trackingMarkerSlice.reducer,
})

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== 'production' && {
    maxAge: 5,
    // stateSanitizer: () => {
    //   return '<<STATE TOO LARGE>>' as any
    // },
  },
})

store.subscribe(() => {
  // TODO: Decide to use redux-persist or not later all
  // Now we only save area key
  // localStorage.setItem(StorageKeys.AREA_REDUCER, JSON.stringify(currentState.area));
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppStartListening = TypedStartListening<RootState, AppDispatch>

export const startAppListening = listenerMiddlewareInstance.startListening as AppStartListening
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store

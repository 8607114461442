import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { TABS, VIEW_AS } from 'constants/bookingCardsConstants'

const initialState: any = {
  currentViewAs: VIEW_AS.card,
  currentTab: TABS[0].scope,
  paginateValues: {},
  draftBookingCards: [],
  bookingCards: []
}

const myBookingSlice = createSlice({
  name: 'myBooking',
  initialState,
  reducers: {
    resetMyBooking: () => initialState,
    updateCurrentViewAs: (state: any, action: PayloadAction<any>) => {
      const url = new URL(window.location as any)
      url.searchParams.set('search[view_as]', action.payload)
      window.history.pushState({}, '', decodeURIComponent(url as any))
      state.currentViewAs = action.payload
    },
    updateCurrentTab: (state: any, action: PayloadAction<any>) => {
      const url = new URL(window.location as any)
      url.searchParams.set('search[scope]', action.payload)
      window.history.pushState({}, '', decodeURIComponent(url as any))
      state.currentTab = action.payload
    },
    updatePaginateValues: (state, action: PayloadAction<any>) => {
      state.paginateValues = action.payload
    },
    // bookingCards
    updateBookingCards: (state, action: PayloadAction<any>) => {
      state.bookingCards = action.payload
    },
    updateBookingCard: (state, action: PayloadAction<any>) => {
      const index = state.bookingCards.findIndex((item: any) => parseInt(item.id, 10) === parseInt(action.payload.id, 10))
      if (index !== -1) {
        state.bookingCards[index] = {
          ...state.bookingCards[index],
          ...action.payload.attrs,
        }
      }
    },

    //draftBookingCards
    updateDraftBookingCards: (state: any, action: PayloadAction<any>) => {
      state.draftBookingCards = action.payload
    },
    deleteDraftBookingCard: (state: any, action: PayloadAction<any>) => {
      _.remove(state.draftBookingCards, { id: action.payload })
    },
  },
  extraReducers: () => {},
})

export const myBookingActionsCreators = myBookingSlice.actions

export default myBookingSlice

import moment from 'moment'
import get from 'lodash/get'
import _ from 'lodash'
import $ from 'jquery'

// UTILS
import {
  currentVehicleType, isCustomerEditBooking, convertCustomReimbursementToParam,
} from 'utils/new_booking/common'
import { Utils } from 'utils/Utils'
import { TallyUtils } from 'utils/booking/TallyUtils'
import { CPODUtils } from 'utils/booking/CPODUtils'
import toastr from 'utils/toast';
// API
import BookingAPI from 'api/bookings'
import CustomerAPI from 'api/customers'
import AwsImageUpload from 'api/awsImageUpload'
import SubAccountAPI from 'api/subAccountTag'
// ACTIONS
import { checkDiscountCode, showPopupWhenDiscountInvalid } from '../new_booking/discountCodeActionCreators'
import { clearExtraRequirementLocations, updateLocation } from '../new_booking/locationActionCreators'
import I18n from 'i18n/i18n'

// CONSTANTS
import {
  LONG_HAUL,
  FULL_DAY,
  STATUS_CANCELLED,
  NOW,
  TEN_SECONDS,
  ONE_MIN_TO_MS,
  CUSTOMER_PAYMENT,
  SECTION_TYPE_PAYMENT_LIST,
  SPECIAL_SETTINGS_AREA,
  METHOD_CASH,
} from 'constants/bookingConstants'
import {
  DRIVER_PREFERENCES
} from 'constants/newBookingConstants'
import { DISCOUNT_CODE_STATUS } from 'constants/discountCodeConstants'

import { isBookAgain, isEditBooking, isNewDriver, isRetryAssigned, setAttachments, paramForGetBookingDetail } from 'utils/booking/common'
import LocationAPI from 'api/locations'
import { attachmentsActionsCreator } from 'store/toolkit/newBooking/attachments.reducer'
import { bookingAgainDetailsActionsCreator } from 'store/toolkit/newBooking/bookingAgainDetails.reducer'
import { dataChangesActionsCreator } from 'store/toolkit/newBooking/dataChange.reducer'
import { documentReturnActionsCreator } from 'store/toolkit/newBooking/documentReturn.reducer'
import { othersActionsCreator } from 'store/toolkit/newBooking/others.reducer'
import { roundTripDiscountActionsCreator } from 'store/toolkit/newBooking/roundTripDiscount.reducer'
import { subAccountTagPickedActionsCreator } from 'store/toolkit/subAccountTagPicked/subAccountTagPicked.reducer'
import { timeTypeActionsCreator } from 'store/toolkit/newBooking/timeType.reducer'
import { selectedVehicleTypeIDActionsCreator } from 'store/toolkit/newBooking/selectedVehicleTypeID.reducer'
import { selectedServiceTypeIDActionsCreator } from 'store/toolkit/newBooking/selectedServiceTypeID.reducer'
import { bookingActionsCreator } from 'store/toolkit/bookings/booking.reducer'
import { checkSubAccountActionsCreator } from 'store/toolkit/checkSubAccount/checkSubAccount.reducer'
import { typeBooking } from 'constants/CommonConstant'
import { bookingsActionsCreator } from 'store/toolkit/bookings/bookings.reducer'
import { requireSignaturesActionsCreator } from 'store/toolkit/requireSignatures/requireSignatures.reducer'
import { subAccountTagListActionsCreator } from 'store/toolkit/subAccountTagList/subAccountTagList.reducer'
import { currentStepActionsCreator } from 'store/toolkit/currentStep/currentStep.reducer'
import { pickupTimeActionsCreator } from 'store/toolkit/newBooking/pickupTime.reducer'
import { timeTypeUIActionsCreator } from 'store/toolkit/newBooking/timeTypeUI.reducer'
import { quickChoiceActionsCreator } from 'store/toolkit/newBooking/quickChoice/quickChoice.reducer'
import { isNewDriverActionsCreator } from 'store/toolkit/newBooking/assignDriver/isNewDriver.reducer'
import { extraServicesActionsCreator } from 'store/toolkit/extraServices/extraServices.reducer'
import { discountCodeActionsCreator } from 'store/toolkit/newBooking/discountCode.reducer'
import { jobOrderNumberActionsCreator } from 'store/toolkit/newBooking/jobOrderNumber.reducer'

import { hideLoading, showLoading } from 'assets/javascripts/webapp-v2/common'
import PaymentIntegrationAPI from 'api/payment-integration'
import { updateExtraInfos } from './extraInfosCreators'
import SettingAPI from 'api/settings'
import store from 'store/store'
// ASSETS

// FOR BOOKING

export const updateBookAgainDetails = bookAgainDetails => (dispatch) => {
  dispatch(extraServicesActionsCreator.setPreVehicleTypeId(bookAgainDetails.vehicle_type_id))
  dispatch(extraServicesActionsCreator.updateExtraServicesTimeType(bookAgainDetails.time_type))
  dispatch(bookingAgainDetailsActionsCreator.updateBookAgainDetails(bookAgainDetails))
}

export const updateBookAgainSettlementDetails = settlementDetails => bookingAgainDetailsActionsCreator.updateBookAgainSettlementDetails(settlementDetails)

export const updateRequireSignatures = value => requireSignaturesActionsCreator.updateRequireSignatures(value)

export const getBookingDetailAction = (callback = (() => { }), id) => async (dispatch, getState) => {
  const includeParams = [...paramForGetBookingDetail]
  const state = getState()
  const { booking } = state
  if (booking.tracking_token) {
    includeParams.push('tracking_token')
  }
  const params = {
    'include': includeParams,
  }
  const result = await BookingAPI.getBookingInfoApi(id || booking.id, params)
  if(!_.isEmpty(result?.data?.object)) {
    dispatch(bookingActionsCreator.bookingUpdate({
      booking: result?.data?.object
    }))
    
  }
  callback && callback()
}

export const getInfoMarketingBooking = (quoteID, callback = (() => { })) => async (dispatch, getState) => {
  const dataQuoteBooking = await BookingAPI.getBookingFromQuote(quoteID)
  if (_.isEmpty(dataQuoteBooking)|| dataQuoteBooking.error) {
    callback()
    return 
  }
  const { serviceTypes } = getState()
  const serviceTypeId = dataQuoteBooking?.service_type_id
  const vehicleTypeId = dataQuoteBooking?.vehicle_type_id
  const serviceType = serviceTypes.find(st => st.id === serviceTypeId) || serviceTypes.find(service => service.is_default) || serviceTypes[0]
  const vehicleType = serviceType?.vehicle_types?.find(vt => vt.id === vehicleTypeId) || serviceType?.vehicle_types?.[0]
  const bufferTime = (vehicleType?.settings?.buffer_time_for_pick_time_single || 0) * ONE_MIN_TO_MS
  const datetime = new Date((dataQuoteBooking?.pickup_time * TEN_SECONDS) + bufferTime).toString()

  dispatch(updateBookAgainDetails(dataQuoteBooking))
  dispatch(selectedServiceTypeIDActionsCreator.setSelectedServiceTypeId(serviceType.id))
  dispatch(selectedVehicleTypeIDActionsCreator.setSelectedVehicleTypeId(vehicleType.id))
  dispatch(timeTypeActionsCreator.changeTimeType(dataQuoteBooking?.time_type))
  dispatch(pickupTimeActionsCreator.changePickupTime(datetime))
  dispatch(roundTripDiscountActionsCreator.updateRoundTripDiscount(dataQuoteBooking?.round_trip_discount))
  dispatch(documentReturnActionsCreator.updateDocumentReturn(dataQuoteBooking?.booking_tracking))
  dispatch(requireSignaturesActionsCreator.updateRequireSignatures(dataQuoteBooking?.require_signatures))
  if (!_.isEmpty(dataQuoteBooking.booking_attachments)) {
    const attachmentsList = setAttachments(dataQuoteBooking.booking_attachments)
    dispatch(attachmentsActionsCreator.updateAttachments(attachmentsList))
  }
  callback()
}

export const getInfoEditBooking = (bookingId, isBookAgain, navigate, callback = () => undefined) => async (dispatch, getState) => {
  const currentStep = getState().currentStep
  const res = await BookingAPI.getBookingInfoForEdit(bookingId, isBookAgain, navigate)
  if(res.status === 500) {
    toastr.error(I18n.t('errors.normal.permission_denied'))
    window.location.href = '/'
  }
  const dataEditBooking = res?.data?.object
  if (_.isEmpty(dataEditBooking)) return
  const isNewDriverEdit = isNewDriver()
  let step = 1
  if (isRetryAssigned()) step = 3
  if (isNewDriverEdit) step = 2
  dispatch(jobOrderNumberActionsCreator.updateJobOrderNumber(dataEditBooking.job_order_number))
  dispatch(quickChoiceActionsCreator.changeQuickChoice(dataEditBooking.quick_choice_id))
  dispatch(timeTypeUIActionsCreator.changeTimeTypeUI(dataEditBooking.display_time_type))
  dispatch(timeTypeActionsCreator.changeTimeType(dataEditBooking?.time_type))
  const datetime = new Date(dataEditBooking.pickup_time * TEN_SECONDS).toString()
  dispatch(pickupTimeActionsCreator.changePickupTime(datetime))
  
  dispatch(selectedVehicleTypeIDActionsCreator.setSelectedVehicleTypeId(dataEditBooking?.vehicle_type_id))
  dispatch(selectedServiceTypeIDActionsCreator.setSelectedServiceTypeId(dataEditBooking?.service_type_id))
  
  dispatch(roundTripDiscountActionsCreator.updateRoundTripDiscount(dataEditBooking?.round_trip_discount))
  dispatch(documentReturnActionsCreator.updateDocumentReturn(dataEditBooking?.booking_tracking))
  dispatch(isNewDriverActionsCreator.setIsNewDriver(isNewDriverEdit))
  if (dataEditBooking?.discount_code_info && !isBookAgain) {
    dispatch(discountCodeActionsCreator.updateDiscountCode({ status: 'valid', value: dataEditBooking?.discount_code_info }))
  }
  if (isEditBooking()) {
    dispatch(requireSignaturesActionsCreator.updateRequireSignatures(dataEditBooking?.require_signatures))
  }
  if (currentStep) {
    dispatch(currentStepActionsCreator.changeStep(step))
  }
  if (!_.isEmpty(dataEditBooking.booking_attachments)) {
    const attachmentsList = setAttachments(dataEditBooking.booking_attachments)
    dispatch(attachmentsActionsCreator.updateAttachments(attachmentsList))
  }
  dispatch(updateBookAgainDetails(dataEditBooking))
  callback()
}

export const retryBooking = (options = {}) => async (dispatch) => {
  const params = {
    id: options.id,
    assign_driver_booking_attributes: options.driver_id
      ? {
        driver_id: options.driver_id,
        fleet_partner_id: options.fleet_partner_id
      }
      : undefined
  }

  showLoading()
  await BookingAPI.retry(params)
  hideLoading()
  dispatch(getBookingDetailAction())
}

export const favorite = value => (dispatch, getState) => {
  const state = getState()
  return BookingAPI.favoriteBooking(state.booking.id, value, () => {
    dispatch(getBookingDetailAction())
  })
}

export const updateSubAccountCheckBox = value => checkSubAccountActionsCreator.updateSubAccountCheckBox({ value: value })

export const addSubAccount = (subAccountTagPicked = {}) => subAccountTagPickedActionsCreator.subAccountTagPicked(subAccountTagPicked)

const createBooking = (state, dispatch, discountInvalid, callback) => (bookingAttachmentIds) => {
  // eslint-disable-next-line consistent-return
  BookingAPI.create(state, discountInvalid, bookingAttachmentIds, (res) => {
    if (!res) { // Payload Too Large
      dispatch(checkSubAccountActionsCreator.updateSubAccountCheckBox({ value: false }))
      dispatch(addSubAccount())
    }
    dispatch(callback(res))

    return null
  })
}

const uploadBookingAttachments = (state, callback) => {
  const attachments = _.filter(state.attachments, attachment => attachment.file !== undefined)
  const accessToken = state.currentCustomer.authentication_token
  // call api to upload attachment to s3
  const bookingAttachmentIds = _.map(_.filter(state.attachments, attachment => attachment.id), 'id')
  if (!_.isEmpty(attachments)) {
    let attachmentProcessed = 0
    // eslint-disable-next-line array-callback-return
    attachments.forEach((attachment) => {
      AwsImageUpload.getPutUrl(
        accessToken,
        {
          attachment: {
            id: attachment.id,
            document_content_type: attachment.document_content_type,
            document_file_name: attachment.document_file_name,
            document_file_size: attachment.document_file_size,
            document_type: attachment.document_type,
          }
        },
        attachment.file,
        (data, error) => {
          if (!error) {
            bookingAttachmentIds.push(data.photoID)
            attachmentProcessed += 1
            if (attachmentProcessed === attachments.length) {
              callback(bookingAttachmentIds)
            }
          }
        }
      )
    })
  } else {
    callback(bookingAttachmentIds)
  }
}

// for step 3 new booking only
export const create = callback => async (dispatch, getState) => {
  try {
    const state = getState()
    const {
      discountCode = {}
    } = state
    const checkDiscount = await dispatch(checkDiscountCode(discountCode.value))
    const {
      discount_invalid: discountInvalid
    } = checkDiscount
    if (discountCode?.value && discountInvalid) dispatch(showPopupWhenDiscountInvalid(checkDiscount))
    else {
      uploadBookingAttachments(
        state,
        createBooking(state, dispatch, discountInvalid, callback)
      )
    }
  } catch (error) {
    throw new Error(error)
  }
}

// Helper functions calculate
const createBookingFromResponse = (state, response) => {
  const allBadges = state.extraServices.companyBadges.concat(state.extraServices.vehicleTypeBadges)
  const booking = {
    ...response.object,
    newPayment: response?.payment || {},
    booking_extra_requirements: _.filter(state.extraServices.extraRequirements, { selected: true }),
    booking_extra_requirements_negative_position: _.filter(state.extraServices.extraRequirementsNegativePosition, { selected: true }),
    booking_badges: _.filter(allBadges, { selected: true }),
    vehicle_type: currentVehicleType(state),
    quote_id: response.quote_id,
    has_update_price: response.has_update_price || false
  }
  return booking
}

const handleCreditUsage = (state, booking, dispatch) => {
  const { booking: oldBooking } = state || {}
  const { total_fees: totalFees } = booking || {}
  const {
    currentCustomer: {
      authentication_token: authToken,
      current_company_id: companyID,
    },
  } = state
  const newBooking = { ...booking };

  if (_.isUndefined(oldBooking.use_credit) && authToken) {
    if (!companyID) {
      newBooking.use_credit = !!newBooking.credit_amount && newBooking.use_credit
    }
  } else if (!!newBooking.credit_amount && !totalFees && !companyID) {
    const firstLocation = state.locations[0]
    dispatch(updateLocation(firstLocation.id, { is_payer: true }))
  }

  return newBooking;
}
// End Helper functions calculate

// for step 2 -> step 3 on new booking only
export const calculate = callback => (dispatch, getState) => {
  BookingAPI.calculate(getState(), (response) => {
    if (response?.error) {
      toastr.error(response.error)
      $('#loading-modal').removeClass('visible')
      return null
    }

    const state = getState()
    let booking = createBookingFromResponse(state, response)

    booking = handleCreditUsage(state, booking, dispatch)
    Promise.resolve(
      dispatch(bookingActionsCreator.bookingUpdate({ booking }))
    ).then(() => {
      if (typeof callback === 'function') {
        callback(booking)
      }
    })
  })
}

export const calculateCashback = (callback, isServiceUpdate) => (dispatch, getState) => {
  BookingAPI.calculateCashback(getState(), (response) => {
    if (response === null) {
      dispatch(bookingActionsCreator.bookingUpdateCashBackAmount(response))
      return
    }
    if (response.error) {
      // toastr.error(response.error)
      $('#loading-modal').removeClass('visible')
      return
    }
    Promise.resolve(
      dispatch(updateBookingAttributes({ cashBack: response?.object }))
    ).then(() => {
      if (typeof callback === 'function') {
        callback()
      }
    })
  }, isServiceUpdate)
}

/**
 * for step2 we get data from extra-service and calculate it locally.
 *  we don't want to trigger api call to reduce bandwidth
 * But there is an disadvantage here is we have to update webapp code
 *    whenever we need to change the related business logic
 */
export const calculateExtraServicesPrice = () => (dispatch, getState) => {
  const state = getState()
  const {
    booking,
    timeType,
    bookAgainDetails,
    outOfServiceStatus,
    locations,
  } = state
  const price = TallyUtils.calculateExtraServicesPrice(
    {
      extraServices: state.extraServices,
      booking,
      timeType,
      bookAgainDetails,
      outOfServiceStatus,
      locations,
    }
  )

  return Promise.resolve(
    dispatch(bookingActionsCreator.bookingUpdateTally({
      value: {
        step2Price: price,
      },
    }))
  )
}

// Helper Functions getTallyData
const fetchTallyData = ({ state, specificStep, isLocationChanged, isValidLH, dispatch }) => {
  return Promise.resolve(
    BookingAPI.getTallyData({
      state,
      specificStep,
      isLocationChanged,
      isValidLH
    }, (response) => {
      const value = prepareTallyData(state, response, specificStep, isValidLH)

      Promise.resolve(
        dispatch(bookingActionsCreator.bookingUpdateTally({ value }))
      )
    })
  )
}

const prepareTallyData = (state, response, specificStep, isValidLH) => {
  let value = _.defaults(
    _.pick(
      response?.object,
      ['total_distance', 'transit_time', 'worst_transit_time', 'is_out_of_service', 'eta_locations_id', 'routes']
    ),
    {
      worst_transit_time: null,
      is_out_of_service: false,
    }
  )

  value = handleTransitTimeForFullDay(state, value, isValidLH)
  value = handleStepSpecificPricing(value, response, specificStep)

  return value
}

const handleTransitTimeForFullDay = (state, value, isValidLH) => {
  const newValue = { ...value };
  if (state.timeType === FULL_DAY && !isValidLH) {
    if (state.booking.transit_time) {
      delete newValue.transit_time
    }
    delete newValue.worst_transit_time
  }

  return newValue;
}

const handleStepSpecificPricing = (value, response, specificStep) => {
  const newValue = { ...value };

  if (specificStep) {
    newValue[`step${specificStep}Price`] = response.object?.subtotal
    newValue[`step${specificStep}OutOfServiceAreaFee`] = response.object?.out_of_service_area_fee || 0
  } else {
    newValue.subtotal = response.object?.subtotal
  }

  return newValue;
}
// End Helper Functions getTallyData

export const getTallyData = ({
  specificStep = null,
  isLocationChanged = true,
  isValidLH = false
}) => (dispatch, getState) => {
  const state = getState()

  return new Promise((resolve) => {
    const locations = (state.locations || []).filter(location => location.lat && location.lng)
    if (!locations.length) {
      return resolve()
    }

    fetchTallyData({
      state,
      specificStep,
      isLocationChanged,
      isValidLH,
      dispatch,
    }).then(resolve)
  })
}

export const resetTallyDataStep2 = () => (dispatch, getState) => {
  // mark new_booking to reset bookingAgainDetail when moving to next step
  dispatch(bookingActionsCreator.bookingUpdateTally({
    value: { step2Price: 0 },
  }))
  dispatch(clearExtraRequirementLocations())
}

// This api to fetch transit time for full-day time type
// for non-full day please use getTallyData
export const getTallyTransitTime = ({ isValidLH = false, callback }) => (dispatch, getState) => {
  if (isValidLH) return callback()
  return BookingAPI.getTallyTransitTime(getState(), (response) => {
    const value = _.defaults(
      _.pick(response, ['transit_time', 'worst_transit_time']),
      {
        transit_time: null,
        worst_transit_time: null,
      }
    )

    Promise.resolve(
      dispatch(bookingActionsCreator.bookingUpdateTally({
        value,
      }))
    ).then(() => callback())
  })
}


export const subAccountTagListToState = (payload = []) => (dispatch) => {
  dispatch(subAccountTagListActionsCreator.subAccountTagList(payload))
}

export const getSubAccountTagList = (params = {}) => (dispatch, getState) => {
  const state = getState()
  const currentCustomer = state.currentCustomer
  let newParams = { ...params }
  if (currentCustomer.current_company_id) {
    newParams = { ...newParams, company_id: currentCustomer.current_company_id }
  }
  SubAccountAPI.getSubAccountTagList(
    currentCustomer.authentication_token,
    newParams,
    (res) => {
      dispatch(subAccountTagListToState(res?.data.data))
    }
  )
}

const generateEstimationTransitTimeAttributes = ({
  booking, pickupTime, timeType, quickChoiceID,
}) => {
  const estimateTransitTimesAttributes = []
  /* eslint-disable no-param-reassign */
  // for 'quick' time type (timeType = 'now' and no quickChoice)
  if (timeType === NOW && !quickChoiceID) {
    pickupTime = moment().format()
  }

  if (pickupTime && booking.transit_time) {
    const estimateTransitTimes = {
      eta: moment(pickupTime).add(booking.transit_time, 's').format()
    }
    if (booking.worst_transit_time) {
      estimateTransitTimes.worst_case_eta = moment(pickupTime).add(booking.worst_transit_time, 's').format()
    }

    estimateTransitTimesAttributes.push(estimateTransitTimes)
  }

  return estimateTransitTimesAttributes
}

export const submitEditExtraPerLocationsParams = (location, isCeb) => {
  const extPerLocations = _.filter(location.extra_requirement_locations, e => e.selected_amount > 0)
  return extPerLocations.map((extPerLocation) => {
    const result = {
      extra_requirement_id: extPerLocation.extra_requirement_id,
      selected_amount: extPerLocation.selected_amount,
      unit_price: extPerLocation.unit_price
    }
    if (isCeb && extPerLocation.id) {
      result.id = extPerLocation.id
    }
    return result
  })
}

// Helper Functions submitEditBooking
const buildBookingParams = (state, bookingAttachmentIds) => {
  const { extraServices, attachments, discountCode, booking, bookAgainDetails } = state
  const totalExtraRequirements = extraServices.extraRequirements.concat(
    state.extraServices.extraRequirementsNegativePosition
  );
  const locations = _.filter(state.locations, (location) => location.lat !== undefined);
  const relatedAttachmentIds = _.compact(
    _.map(_.filter(attachments, (attachment) => attachment.attachable_type !== 'Booking'), 'id')
  );
  const allBadges = extraServices.companyBadges.concat(extraServices.vehicleTypeBadges);
  const companySettings = extraServices.companySettings
  const validDiscountCode = discountCode && discountCode.status === DISCOUNT_CODE_STATUS.valid ? discountCode.value : '';
  const customReimbursements = extraServices.customReimbursements;
  const isCeb = isCustomerEditBooking();
  const bookAgainLocations = bookAgainDetails.locations;
  const estimateTransitTimesAttributes = generateEstimationTransitTimeAttributes({
    booking,
    timeType: state.timeType,
    pickupTime: state.pickupTime,
    quickChoiceID: state.quickChoiceID,
  });

  const params = {
    ...buildCoreBookingParams(state, validDiscountCode),
    locations_attributes: locations.map((location, index) => buildLocationAttributes(location, bookAgainLocations[index], booking)),
    booking_extra_requirements_attributes: buildExtraRequirements(totalExtraRequirements, state, isCeb),
    booking_tracking_attributes: CPODUtils.validateParamsDocumentReturn(locations, state.checkLocations)
      ? state.documentReturn
      : null,
    booking_badges_attributes: buildBadgesAttributes(allBadges),
    booking_reimbursements_attributes:
      state.timeType !== LONG_HAUL && !state.extraInfos.enable_parking_tolls_feature
        ? null
        : convertCustomReimbursementToParam(customReimbursements),
    booking_attachment_ids: bookingAttachmentIds,
    related_attachment_ids: relatedAttachmentIds,
    just_signed_in: false,
    job_order_number: state.jobOrderNumber,
    allow_parking_fees: companySettings.allow_parking_fees,
    allow_tolls_fees: companySettings.allow_tolls_fees,
    allow_waiting_time_fees: companySettings.allow_waiting_time_fees,
    send_first_to_favorite: booking.driverPreferencesTab !== DRIVER_PREFERENCES.all,
    round_trip_discount: state.roundTripDiscount,
    assign_driver_booking_attributes: {
      driver_id: state.assignedDriver ? state.assignedDriver.id : null,
      fleet_partner_id: state.assignedDriver ? state.assignedDriver.fleet_partner_id : null,
    },
    is_new_driver: state.isNewDriver,
    is_ceb: isCeb,
    has_settlement: !_.isEmpty(bookAgainDetails.settlement_details),
    require_signatures: state.requireSignatures,
    ...buildAdditionalAttributes(state, estimateTransitTimesAttributes, booking),
    ...(!_.isEmpty(bookAgainDetails.sub_account_tag)
      ? {
          sub_account_tag_attributes: {
            sub_account_id: bookAgainDetails.sub_account_tag.sub_account_id,
            sub_account_name: bookAgainDetails.sub_account_tag.sub_account_name,
          },
        }
      : {}),
    checkSubAccount: state.checkSubAccount,
  };

  if (booking.quote_id) {
    _.assign(params, {
      quote_id: booking?.quote_id,
      has_update_price: booking?.has_update_price,
    })
  }

  return buildPaymentAttributes(params, booking);
};

const buildCoreBookingParams = (state, validDiscountCode) => ({
  id: state.bookAgainDetails.id,
  time_type: state.timeType,
  display_time_type: state.timeTypeUI,
  vehicle_type_id: state.selectedVehicleTypeID,
  service_type_id: state.selectedServiceTypeID,
  full_day_selected_amount: _.toInteger(state.extraServices.fullDayPricing.selected_amount),
  company_id: state.currentCustomer.current_company_id || 0,
  pickup_time: state.pickupTime,
  quick_choice_id: state.quickChoiceID,
  display_quick_choice_id: state.quickChoiceID,
  discount_code: validDiscountCode || '',
  note: state.note,
  marked_as_favorite: state.others.saveToFavorites || false,
});

const buildLocationAttributes = (location, bookAgainLocation, booking) => {
  const extraPerLocations = submitEditExtraPerLocationsParams(location, isCustomerEditBooking());
  const result = {
    latitude: location.lat,
    longitude: location.lng,
    name: location.name,
    extra_requirement_locations_attributes: extraPerLocations,
    description: location.description || '',
    is_payer: location.is_payer || false,
    recipient_name: location.recipient_name,
    recipient_phone: location.recipient_phone,
    need_cod: location.need_cod || false,
    need_pod: location.need_pod || false,
    cod_note: CPODUtils.getPramsCODPODNote(location, 'cod_note', booking),
    pod_note: CPODUtils.getPramsCODPODNote(location, 'pod_note', booking),
    cod_invoice_fees: location.cod_invoice_fees,
    is_phone_mask: !_.isEmpty(location.phone_mask),
  };
  if (bookAgainLocation?.id) {
    result.id = bookAgainLocation.id;
  }
  return result;
};

const buildExtraRequirements = (totalExtraRequirements, state, isCeb) => {
  return _.filter(totalExtraRequirements, (extraRequirement) => {
    if (state.timeType === LONG_HAUL) {
      return extraRequirement.selected === true && extraRequirement.is_flat_per_location === false;
    }
    return extraRequirement.selected === true;
  }).map((extraRequirement) => buildExtraRequirementParams(extraRequirement, isCeb, state));
};

const buildExtraRequirementParams = (extraRequirement, isCeb, state) => {
  const result = {
    extra_requirement_id: extraRequirement.id,
    selected_amount: extraRequirement.selected_amount,
    is_flat: extraRequirement.is_flat,
    position: extraRequirement.position,
    unit_price: extraRequirement.unit_price,
    level_price: extraRequirement.level_price || '',
    extra_requirement_pricing_id: extraRequirement.extra_requirement_pricing_id || '',
  };

  if (!_.isUndefined(extraRequirement.selectedPricing)) {
    result.unit_price = extraRequirement.selectedPricing.fees;
    result.level_price = extraRequirement.selectedPricing.level_price;
    result.extra_requirement_pricing_id = extraRequirement.selectedPricing.id;
  }

  const bookAgainExtra = _.find(state.bookAgainDetails.booking_extra_requirements, (ext) => ext.extra_requirement_id === extraRequirement.id);
  if (isCeb && !_.isUndefined(bookAgainExtra)) {
    result.id = bookAgainExtra.id;
  }

  return result;
};

const buildBadgesAttributes = (allBadges) => _.filter(allBadges, { selected: true }).map((badge) => ({
  badgeable_relation_id: badge.id,
  badgeable_relation_type: badge.badgeable_relation_type,
  selected_amount: badge.selected_amount,
}));

const buildPaymentAttributes = (params, booking) => {
  const newParams = { ...params };
  const bankTransfer = booking?.bankTransfer;
  const paymentMethodForNonBp = booking.payment_method_for_non_bp;
  const isEditWithPayment = Utils.isAddPaymentAttr(bankTransfer, paymentMethodForNonBp);

  newParams.use_credit = booking.use_credit

  if (isEditWithPayment) {
    newParams.payment_attributes = Utils.getPaymentAttributes(bankTransfer);
    newParams['include[]'] = 'payment';
  }

  if (booking?.newPayment?.id || isEditWithPayment) newParams['include[]'] = 'payment';

  return newParams;
};

const buildAdditionalAttributes = (state, estimateTransitTimesAttributes, booking) => {
  const attributes = {};
  if (estimateTransitTimesAttributes.length > 0) {
    attributes.estimate_transit_times_attributes = estimateTransitTimesAttributes;
  }
  if (booking.eta_locations_id) {
    attributes.eta_locations_id = booking.eta_locations_id;
  }
  return attributes;
};
// End Helper Functions submitEditBooking

const submitEditBooking = (callback) => async (bookingAttachmentIds) => {
  const state = store.getState();
  const { bookAgainDetails, extraInfos } = state
  const dispatch = store.dispatch;

  const params = buildBookingParams(state, bookingAttachmentIds);

  const isNewEntry = extraInfos?.enable_new_booking_entry_service;
  const response = await BookingAPI.updateBooking(bookAgainDetails.id, params, isNewEntry);

  if (response.status === 422 && bookAgainDetails?.sub_account_tag) {
    const newBookAgainDetails = { ...bookAgainDetails };
    delete newBookAgainDetails.sub_account_tag;
    dispatch(updateBookAgainDetails(newBookAgainDetails));
  }

  dispatch(callback(response));
};

export const editBooking = callback => async (dispatch, getState) => {
  try {
    const state = getState()
    const {
      discountCode = {}
    } = state
    const checkDiscount = await dispatch(checkDiscountCode(discountCode.value))
    const {
      discount_invalid: discountInvalid
    } = checkDiscount
    if (discountCode?.value && discountInvalid) dispatch(showPopupWhenDiscountInvalid(checkDiscount))
    else uploadBookingAttachments(state, submitEditBooking(callback))
  } catch (error) {
    throw new Error(error)
  }
}
export const updateCancelReasonForBatch = (reasonID, bookingID, user) => () => {
  const params = {
    id: bookingID,
    cancelation_reason_ids: reasonID
  }
  BookingAPI.cancelBooking(bookingID, params, () => { })
}

export const searchDriverByReferralCode = (data, stepActions, callback) => () => {
  const referralCode = _.toLower(_.trim(data.driverReferralCode))
  if (_.isEmpty(referralCode) || !data.time_type) {
    return
  }
  showLoading()
  const driver = _.find(data.drivers, ['referral_code', referralCode])
  if (!_.isUndefined(driver)) {
    callback([driver])
    hideLoading()
    return
  }
  const params = {
    referral_code: referralCode,
    include_fleet_driver: _.isUndefined(data.include_fleet_driver) ? true : data.include_fleet_driver,
    'include': ['driver.vehicles', 'driver.current_vehicle'],
    'only': ['id', 'referral_code', 'name', 'driver_image_url', 'driver_type', 'badges', 'fleet_partner_id', 'fleet_partner_name', 'current_vehicle', 'is_requirement_met', 'is_favorite_driver'],
    'only[][vehicles]': ['vehicle_attributes', 'vehicle_type_name'],
    vehicle_type_id: data.vehicle_type_id,
    time_type: data.time_type,
    company_id: data.company_id,
    area_id: data.area_id,
    country_code: data.country_code,
    'extra_requirement_ids': data.extra_requirement_ids,
    'extra_requirement_locations_ids': data.extra_requirement_locations_ids,
    'badges': data.badges,
    'reimbursement_ids': data.reimbursement_ids,
    check_requirement_not_met: true,
    check_favorite_driver: true,
    location_need_cod: !!data.location_need_cod,
    location_need_pod: !!data.location_need_pod,
    new_gen_pod: !!data.new_gen_pod,
  }
  CustomerAPI.searchDriverByReferralCode(
    data.authenticationToken,
    params,
    (response) => {
      hideLoading()
      if (response.data.error) {
        toastr.remove()
        toastr.error(response.data.error)
      } else {
        callback(response.data.data)
      }
    }
  )
}

export const searchDriverByReferralCodeNew = async (data) => {
  const referralCode = _.toLower(_.trim(data.driverReferralCode))
  if (_.isEmpty(referralCode)|| !data.time_type) {
    return
  }
  showLoading()
  const driver = _.find(data.drivers, ['referral_code', referralCode])
  if (!_.isUndefined(driver)) {
    hideLoading()
    return [driver]
  }
  const params = {
    referral_code: referralCode,
    include_fleet_driver: _.isUndefined(data.include_fleet_driver) ? true : data.include_fleet_driver,
    include: ['driver.vehicles', 'driver.current_vehicle'],
    only: [
      'id',
      'referral_code',
      'name',
      'driver_image_url',
      'driver_type',
      'badges',
      'fleet_partner_id',
      'fleet_partner_name',
      'current_vehicle',
      'is_requirement_met',
      'is_favorite_driver',
    ],
    'only[][vehicles]': ['vehicle_attributes', 'vehicle_type_name'],
    vehicle_type_id: data.vehicle_type_id,
    time_type: data.time_type,
    company_id: data.company_id,
    area_id: data.area_id,
    country_code: data.country_code,
    extra_requirement_ids: data.extra_requirement_ids || [],
    extra_requirement_locations_ids: data.extra_requirement_locations_ids || [],
    badges: data.badges || [],
    reimbursement_ids: data.reimbursement_ids || [],
    check_requirement_not_met: true,
    check_favorite_driver: true,
    location_need_cod: !!data.location_need_cod,
    location_need_pod: !!data.location_need_pod,
    new_gen_pod: !!data.new_gen_pod,
  }
  const { data: res } = await CustomerAPI.searchDriverByReferralCodeNew(params)
  hideLoading()
  if (res.error) {
    toastr.remove()
    toastr.error(data.error)
  } else {
    return res
  }
}

export const checkSearchDriverToAssign = (data, stepActions, callback) => () => {
  showLoading()
  const params = {
    area_id: data.areaID,
    country_code: data.countryCode,
    vehicle_type_id: data.vehicleTypeId,
    time_type: data.timeType,
    company_id: data.companyID,
    extra_requirement_ids: data.extraRequirementIDs,
    extra_requirement_locations_ids: data.extraRequirementLocationsIDs,
    badges: data.badgeIDs,
    reimbursement_ids: data.reimbursementIDs,
    location_need_cod: !!data.location_need_cod,
    location_need_pod: !!data.location_need_pod,
    new_gen_pod: !!data.new_gen_pod,
  }
  CustomerAPI.validateSearchDriverToAssign(
    data.authenticationToken,
    data.driver.id,
    params,
    (response) => {
      hideLoading()
      if (response.data.error) {
        if (response.data.statusCode === 405) {
          $(data.elementInvalidDriverPopup).addClass('visible')
        } else {
          toastr.remove()
          toastr.error(response.data.error)
        }
      } else {
        callback(response.data)
      }
    }
  )
}

export const updateBookingAttributes = attrs => (dispatch, getState) => {
  let booking = getState().booking
  booking = _.assign({}, booking, attrs)
  dispatch(bookingActionsCreator.bookingUpdate({
    booking
  }))
}

export const clearDataChangeForEditBooking = () => (dispatch) => {
  dispatch(dataChangesActionsCreator.updateDataChange(false))
}

// turn booking again detail into state
export const turnBookingAgainDataIntoState = bookAgainDetails => (dispatch) => {
  // for single booking most of actions directly rely on store (state)
  const {
    discount_code_info: discountCode,
  } = bookAgainDetails

  const tasks = []

  if (discountCode) {
    tasks.push(
      dispatch(discountCodeActionsCreator.updateDiscountCode({ value: discountCode, status: DISCOUNT_CODE_STATUS.valid }))
    )
  }

  return Promise.all(tasks)
}

export const createBookingForLTL = formData => (dispatch, getState) => {
  const state = getState()
  BookingAPI.createBookingForLTL(formData, state, (response) => {
    const id = response.data.id
    const { extraInfos } = state
    const defaultCountry = extraInfos.country_code.toLowerCase()
    const currentCompanyId = get(state, 'currentCustomer.current_company_id')
    const idUser = get(state, 'currentCustomer.id')
    const companyId = currentCompanyId || idUser
    Promise.resolve(
    ).then(() => {
      const param = Utils.buildParamToFTL({
        countryCode: defaultCountry,
        areaId: extraInfos.area_id,
        companyId,
      })
      window.location.replace(`${Utils.getLTLCustomerDomain(defaultCountry)}/booking/${id}?${param}`)
    }).catch(() => {
      // window.location.replace('http://customer-cargo.dev.deliveree.com/')
    })
  })
}

export const updateBookingForLTL = (formData, shipmentId) => (dispatch, getState) => {
  const state = getState()
  const { extraInfos } = state
  const defaultCountry = extraInfos.country_code.toLowerCase()
  BookingAPI.updateBookingForLTL(formData, state, () => {
    Promise.resolve(
    ).then(() => {
      const currentCompanyId = get(state, 'currentCustomer.current_company_id')
      const idUser = get(state, 'currentCustomer.id')
      const companyId = currentCompanyId || idUser
      const param = Utils.buildParamToFTL({
        countryCode: defaultCountry,
        areaId: extraInfos.area_id,
        companyId,
      })
      window.location.replace(`
            ${Utils.getLTLCustomerDomain(defaultCountry)}/booking/${shipmentId}?${param}
          `)
    }).catch(() => {
      // console.log('error ==>', error)
    })
  })
}

export const getShipmentLTLDetail = (shipmentId, callback) => (dispatch, getState) => {
  const state = getState()
  const { extraInfos } = state
  BookingAPI.getShipmentLTLDetail(shipmentId, extraInfos.country_code, (response) => {
    Promise.resolve(
      dispatch(bookingActionsCreator.getShipmentLTLDataSuccess({
        value: response.data
      }))
    ).then(() => {
      callback(response.data)
    }).catch(() => {
    })
  })
}

export const updateOutOfServicePartialLoad = value => (dispatch) => {
  dispatch(bookingActionsCreator.updateStatusOutServicePartialLoad({
    value
  }))
}

export const updateReqNotMetReason = (payload, bookingID) => dispatch => {
  if (bookingID) {
    dispatch(bookingsActionsCreator.updateRequirementNotMetReason({
      payload,
      bookingID
    }))
  } else {
    dispatch(bookingActionsCreator.updateRequirementNotMetReason(payload))
  }
}

export const getRequirementNotMet = (data, stepActions) => (dispatch) => {
  stepActions.loading()
  const params = {
    area_id: data.area_id,
    country_code: data.country_code,
    vehicle_type_id: data.vehicle_type_id,
    time_type: data.time_type,
    company_id: data.company_id,
    extra_requirement_ids: data.extra_requirement_ids,
    extra_requirement_locations_ids: data.extra_requirement_locations_ids,
    badges: data.badges,
    reimbursement_ids: data.reimbursement_ids,
    location_need_cod: !!data.location_need_cod,
    location_need_pod: !!data.location_need_pod,
    new_gen_pod: !!data.new_gen_pod,
  }

  CustomerAPI.getRequirementNotMet(
    data.driverID,
    params,
    data.authenticationToken,
    (response) => {
      stepActions.loaded()
      if (response.data.error) {
        toastr.remove()
        toastr.error(response.data.error)
      } else {
        dispatch(updateReqNotMetReason(response.data, data.bookingID))
      }
    }
  )
}

export const loadFavoriteDrivers = (params, callback, type = typeBooking.single) => (dispatch) => {
  if (!params.time_type) return hideLoading()
  const apiParams = {
    include_fleet_driver: _.isUndefined(params.include_fleet_driver) ? true : params.include_fleet_driver,
    'include': ['driver.vehicles', 'driver.current_vehicle'],
    'only': ['id', 'referral_code', 'name', 'driver_image_url', 'driver_type', 'fleet_partner_id', 'fleet_partner_name', 'is_requirement_met', 'is_favorite_driver'],
    'only[][vehicles]': ['vehicle_attributes', 'vehicle_type_name'],
    vehicle_type_id: params.vehicle_type_id,
    time_type: params.time_type,
    area_id: params.area_id,
    country_code: params.country_code,
    'extra_requirement_ids': params.extra_requirement_ids,
    'extra_requirement_locations_ids': params.extra_requirement_locations_ids,
    'badges': params.badges,
    'reimbursement_ids': params.reimbursement_ids,
    check_requirement_not_met: true,
    check_favorite_driver: true,
    include_recently_assigned_driver: true,
    item_type: 'favorite',
    page: params.page,
    location_need_cod: !!params.location_need_cod,
    location_need_pod: !!params.location_need_pod,
    new_gen_pod: !!params.new_gen_pod,
  }

  if (params.company_id) {
    apiParams.company_id = params.company_id
  }

  CustomerAPI.getFavoriteOrBannedDrivers(
    params.authentication_token,
    apiParams,
    (response) => {
      if (response.data.error) {
        toastr.remove()
        toastr.error(response.data.error)
        hideLoading()
      } else {
        let action = {
          drivers: response.data.data || [],
          recentDriverID: response.data.recently_assigned_driver_id || '',
          pagination: response.data.pagination || {},
          loadingMore: params.loadingMore
        }

        if (params.bookingID) {
          action = {
            ...action,
            bookingID: params.bookingID,
          }
        }

        Promise.resolve(
          dispatch(type === typeBooking.multiple ? bookingsActionsCreator.receiveFavoriteDrivers(action) : bookingActionsCreator.receiveFavoriteDrivers(action)),
          callback(response)
        ).then(() => {
          hideLoading()
        })
      }
    }
  )
}

export const addToFavoriteDrivers = assignedDriver => (dispatch, getState) => {
  const { currentCustomer } = getState()
  const companyID = currentCustomer.current_company_id === 0 ? undefined : currentCustomer.current_company_id
  const authenticationToken = currentCustomer.authentication_token
  let params = {
    'customer_drivers_attributes': [
      {
        item_type: DRIVER_PREFERENCES.favorite,
        driver_id: assignedDriver.id,
        company_id: companyID
      }
    ]
  }

  if (assignedDriver.fleet_partner_id) {
    params = {
      fleet_partner_id: assignedDriver.fleet_partner_id,
      driver_id: assignedDriver.id,
      company_id: companyID
    }
    CustomerAPI.addFavoriteFleetDriver(authenticationToken, params)
  }

  CustomerAPI.addFavoriteDriver(authenticationToken, params)
}

export const signInProgressFinished = isFinished => bookingActionsCreator.signInProgressFinished(isFinished)

export const getFavoriteAmount = (params, cb) => async (dispatch) => {
  const apiParams = {
    include_fleet_driver: _.isUndefined(params.include_fleet_driver) ? true : params.include_fleet_driver,
    vehicle_type_id: params.vehicle_type_id,
    area_id: params.area_id,
    country_code: params.country_code,
    item_type: 'favorite',
  }

  if (params.company_id) {
    apiParams.company_id = params.company_id
  }

  const result = await CustomerAPI.getFavoriteAmount(params.authentication_token, apiParams)
  const amount = result?.data?.error ? 0 : result.data
  if (params.bookingID) {
    dispatch(bookingsActionsCreator.updateBooking({ id: params.bookingID, attrs: { favoriteAmount: amount } }))
  } else {
    dispatch(othersActionsCreator.updateOthers({ attrs : { favoriteDriversAmount: amount } }))
  }

  if (cb) {
    cb()
  }

}

export const updateBookingAction = payload => bookingActionsCreator.bookingUpdate({
  booking: payload,
})

export const getBookingPaymentAction = (
  id, sectionType = SECTION_TYPE_PAYMENT_LIST[CUSTOMER_PAYMENT],
) => async (dispatch) => {
  const params = {
    id,
    section_type: sectionType,
  }
  const bookingPaymentDetails = await CustomerAPI.getCustomerBooking(id, params)
  dispatch(updateBookingAttributes({ bookingPaymentDetails: { ...bookingPaymentDetails?.payment } }))
}

export const getBookingPaymentSettingAction = (id, sectionType, callback = () => undefined) => async (dispatch) => {
  const params = {
    id,
    section_type: sectionType,
  }
  const settingPayment = await CustomerAPI.getCustomerBooking(id, params)
  if (!_.isEmpty(settingPayment?.special_settings)) {
    dispatch(updateBookingAttributes({ special_settings: { ...settingPayment.special_settings } }))
    dispatch(bookingAgainDetailsActionsCreator.updateBookAgainDetails({ special_settings: { ...settingPayment.special_settings } }))
    callback()
  }
}

export const handleInvalidPaymentAction = (countryCode, bankId, handleClose = () => undefined) => async (dispatch) => {
  showLoading()
  const dataAPI = await PaymentIntegrationAPI.getPaymentSettingAPI(countryCode)
  if (dataAPI.status === 200) {
    dispatch(updateExtraInfos({ paymentSettings: [...dataAPI.data] }))
    const paymentSettings = dataAPI.data
    if (bankId) {
      const selectedItem = paymentSettings.find(item => item.id === bankId)
      if (selectedItem) dispatch(updateBookingAction({ bankTransfer: selectedItem }))
      else {
        const defaultItem = paymentSettings.find(item => item.settings.checkByDefault === true && item.method !== METHOD_CASH) || {}
        dispatch(updateBookingAction({ bankTransfer: defaultItem }))
      }
    } else {
      const defaultItem = paymentSettings.find(item => item.settings.checkByDefault === true && item.method !== METHOD_CASH) || {}
      dispatch(updateBookingAction({ bankTransfer: defaultItem }))
    }
    dispatch(calculate(() => {
      Utils.scrollTopAtStep3()
      handleClose()
      hideLoading()
    }))
  } else hideLoading()
}

export const getSpecialSettingsAction = (areaId) => async (dispatch) => {
  const specialSettingsData = await SettingAPI.getSettingsCustomerByArea(areaId, SECTION_TYPE_PAYMENT_LIST[SPECIAL_SETTINGS_AREA])
  if (specialSettingsData.status === 200) {
    const settingPayment = specialSettingsData.data
    dispatch(updateBookingAttributes({ special_settings: { ...settingPayment.special_settings } }))
    if (isCustomerEditBooking() || isBookAgain()) dispatch(
      bookingAgainDetailsActionsCreator.updateBookAgainDetails({ special_settings: { ...settingPayment.special_settings } })
    )
  }
}
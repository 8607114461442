import perPageSlice from 'store/toolkit/pagination/perPage.reducer'
import totalSlice from 'store/toolkit/pagination/total.reducer'

const paginationReducer = {
  // pagination
  perPage: perPageSlice.reducer,
  total: totalSlice.reducer,

}

export default paginationReducer

import listBatchesSlice from 'store/toolkit/batch/listBatches.reducer'
import selectedBatchSlice from 'store/toolkit/selectedBatch/selectedBatch.reducer'
import batchTrackingMarkersSlice from 'store/toolkit/trackingMarkers/batchTrackingMarkers.reducer'
import { combineReducers } from 'redux'


 // ===== BatchManagementReducer
const batchManagementReducer = {
 selectedBatch: selectedBatchSlice.reducer,
 batchManagement: combineReducers({
  batchTrackingMarkers: batchTrackingMarkersSlice.reducer,
  listBatches: listBatchesSlice.reducer,
 })
}

export default batchManagementReducer

import $ from 'jquery'
import _ from 'lodash'

import BookingAPI from 'api/bookings'
import { CANCELED, DRIVER_PREFERENCES } from 'constants/newBookingConstants'
import {
  SECTION_TYPE_PAYMENT_LIST,
  PAYMENT_VA_STATUS,
  CUSTOMER_PAYMENT,
  CUSTOMER_PAYMENT_STATUS,
} from 'constants/bookingConstants'
import { myBookingActionsCreators } from 'store/toolkit/myBooking.reducer'
import { currentCustomerActionsCreator } from 'store/toolkit/currentCustomer/currentCustomer.reducer'
import { updateCustomer } from '../common/customerActionCreators'
import { hideLoading } from 'assets/javascripts/webapp-v2/common'
import CustomerAPI from 'api/customers'

export const getBookings =
  (params = {}) =>
  (dispatch, getState) => {
    const { currentCustomer } = getState()
    return new Promise((resolve) => {
      CustomerAPI.getBookings(currentCustomer.authentication_token, params, (response) => {
        if (!_.isEmpty(response?.data)) {
          dispatch(myBookingActionsCreators.updatePaginateValues(response.data.pagination || {}))
          dispatch(myBookingActionsCreators.updateBookingCards(response.data.data || []))
          dispatch(myBookingActionsCreators.updateDraftBookingCards([]))
          hideLoading()
          resolve()
        }
      })
    })
  }

export const getDraftBookings = (params) => (dispatch, getState) => {
  const { currentCustomer } = getState()
  return new Promise((resolve) => {
    CustomerAPI.getDraftBookings(currentCustomer.authentication_token, params, (response) => {
      if (response?.data) {
        dispatch(myBookingActionsCreators.updatePaginateValues(response.data.pagination || {}))
        dispatch(myBookingActionsCreators.updateDraftBookingCards(response.data.data || []))
        dispatch(myBookingActionsCreators.updateBookingCards([]))
        hideLoading()
        resolve()
      }
    })
  })
}

export const deleteShipmentLTLUnList =
  (shipmentId, callback = () => undefined) =>
  (dispatch, getState) => {
    const state = getState()
    const { extraInfos } = state
    const defaultCountry = extraInfos.country_code.toLowerCase()
    return new Promise(() => {
      BookingAPI.deleteShipmentLTLUnList(shipmentId, defaultCountry, (response) => {
        if (response.data.isSuccess === true) {
          callback()
        }
      })
    })
  }

export const deleteDraft = (id) => (dispatch, getState) => {
  const { currentCustomer } = getState()
  CustomerAPI.deleteDraftBooking(currentCustomer.authentication_token, id, (response) => {
    if (response.data.success === true) {
      Promise.resolve(dispatch(myBookingActionsCreators.deleteDraftBookingCard(id)))
      hideLoading()
    }
  })
}

export const updatePreferenceListDrivers = (params) => async (dispatch, getState) => {
  const { currentCustomer } = getState()
  const { favorite_driver_ids, banned_driver_ids } = currentCustomer
  const driverId = params.customer_drivers_attributes[0].driver_id
  const itemType = params.customer_drivers_attributes[0].item_type
  const apiParams = {
    customer_drivers_attributes: params.customer_drivers_attributes,
  }
  let updateFieldCustomer = {}
  const favDriverIds = favorite_driver_ids ? [...favorite_driver_ids] : []
  const banDriverIds = favorite_driver_ids ? [...banned_driver_ids] : []
  const response = await CustomerAPI.addFavoriteDriver(params.authentication_token, apiParams)
  if (response.data.success === true) {
    const indexBanned = _.findIndex(banDriverIds, (item) => item === driverId)
    const indexFavorite = _.findIndex(favDriverIds, (item) => item === driverId)
    if (indexFavorite > -1) {
      favDriverIds.splice(indexFavorite, 1)
    }
    if (indexBanned > -1) {
      banDriverIds.splice(indexBanned, 1)
    }
    if (itemType === DRIVER_PREFERENCES.favorite) {
      const updateFavDriverIds = indexFavorite > -1 ? favDriverIds : _.concat(favDriverIds, [driverId])
      updateFieldCustomer = { favorite_driver_ids: updateFavDriverIds, banned_driver_ids: banDriverIds }
    } else {
      const updateBanDriverIds = indexBanned > -1 ? banDriverIds : _.concat(banDriverIds, [driverId])
      updateFieldCustomer = { banned_driver_ids: updateBanDriverIds, favorite_driver_ids: favDriverIds }
    }
    dispatch(currentCustomerActionsCreator.updateCustomer(updateFieldCustomer))
    $('#loading-modal').removeClass('visible')
  }
}

export const updatePreferenceFleetListDrivers = params => (dispatch, getState) => {
  const { currentCustomer } = getState()
  const { favorite_driver_ids: favDriverIds, banned_driver_ids: banDriverIds } = currentCustomer
  CustomerAPI.updatePreferenceListDrivers(params.authentication_token, params, (response) => {
    if (response?.data?.success) {
      const driverId = params.customer_drivers_attributes[0].driver_id

      const indexBanned = _.findIndex(banDriverIds, item => (item === driverId))
      const indexFavorite = _.findIndex(favDriverIds, item => (item === driverId))
      const newFavList = [...favDriverIds]
      const newBanList = [...banDriverIds]
      if (indexFavorite > -1) newFavList.splice(indexFavorite, 1)
      if (indexBanned > -1) newBanList.splice(indexBanned, 1)
      const updateFavDriverIds = indexFavorite > -1 ? newFavList : _.concat(newFavList, [driverId])
      const newCurrentCustomer = {
        ...currentCustomer,
        favorite_driver_ids: updateFavDriverIds,
        banned_driver_ids: newBanList,
      }
      dispatch(updateCustomer(newCurrentCustomer))
      hideLoading()
    }
  })
}

export const updateSubAccountCheckBoxCardBooking = (bookingId, value) => (dispatch) => {
  const attrs = {
    isCheckSubAccount: value,
  }
  if (bookingId) {
    dispatch(myBookingActionsCreators.updateBookingCard({ id: bookingId, attrs }))
  }
}

export const addSubAccountCardBooking = (bookingId, data) => (dispatch) => {
  let attrs = {
    sub_account_tag: {
      sub_account_id: undefined,
      sub_account_name: undefined,
    },
  }
  if (data) {
    attrs = {
      sub_account_tag: {
        sub_account_id: data.id,
        sub_account_name: data.name,
      },
    }
  }
  if (bookingId) {
    dispatch(myBookingActionsCreators.updateBookingCard({ id: bookingId, attrs }))
  }
}

const handleDataPaymentDetail = (id, dispatch, bookingCardPaymentDetails, dataPayment) => {
  if (!_.isEmpty(dataPayment)) {
    dispatch(myBookingActionsCreators.updateBookingCard({ id, attrs: { payment: { ...bookingCardPaymentDetails?.payment } } }))
  }
  if (_.isEmpty(bookingCardPaymentDetails) && !_.isNil(bookingCardPaymentDetails) && !_.isUndefined(bookingCardPaymentDetails)) {
    dispatch(myBookingActionsCreators.updateBookingCard({ id, attrs: { payment: { status: PAYMENT_VA_STATUS.virtualAccountTimeout } } }))
  }
}
export const getBookingCardPaymentDetailAction =
  (id, sectionType = SECTION_TYPE_PAYMENT_LIST[CUSTOMER_PAYMENT]) =>
  async (dispatch) => {
    const params = {
      id,
      section_type: sectionType,
    }
    const bookingCardPaymentDetails = await CustomerAPI.getCustomerBooking(id, params)
    const dataPayment = bookingCardPaymentDetails?.payment
    handleDataPaymentDetail(id, dispatch, bookingCardPaymentDetails, dataPayment)
  }
export const getBookingCardPaymentDetailRemovedAction =
  (id, sectionType = SECTION_TYPE_PAYMENT_LIST[CUSTOMER_PAYMENT_STATUS]) =>
  async (dispatch) => {
    const params = {
      id,
      section_type: sectionType,
    }
    const bookingCardPaymentDetails = await CustomerAPI.getCustomerBooking(id, params)
    const dataPayment = bookingCardPaymentDetails?.payment
    if (bookingCardPaymentDetails?.canceled_by_customer && bookingCardPaymentDetails?.status === CANCELED) {
      dispatch(myBookingActionsCreators.updateBookingCard({ id, attrs: { status: CANCELED } }))
    } else handleDataPaymentDetail(id, dispatch, bookingCardPaymentDetails, dataPayment)
  }